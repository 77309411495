import RightArrowIcon from '@ping/assets/Icon/right-arrow.svg';
import { useMobile } from '@ping/hooks';
import { isUserActivityStatusActiveSelector, useUserInformationStore } from '@ping/stores/userInformation.store';
import clsx from 'clsx';
import Link, { LinkProps } from 'next/link';
import { useRouter } from 'next/router';
import { MouseEventHandler, ReactElement } from 'react';
import { NavDropdown } from 'react-bootstrap';

import style from './style.module.scss';

export type TNavbarDropdownItem = {
  href: LinkProps['href'];
  title: string;
  icon: ReactElement;
  className: string;
  onClick?: MouseEventHandler<HTMLAnchorElement>;
  hasTopSeparator?: boolean;
  hasBottomSeparator?: boolean;
  isMobileOnly?: boolean;
  label?: string;
  hasArrow?: boolean;
};

interface INavbarDropdownProps {
  options: TNavbarDropdownItem[];
  className: string;
  onLinkClick?: () => void;
}

export const NavbarDropdownItems = ({ options, className = '', onLinkClick }: INavbarDropdownProps) => {
  const isUserActivityStatusActive = useUserInformationStore(isUserActivityStatusActiveSelector);
  const router = useRouter();
  const isMobile = useMobile();

  const checkIfLinkDisabled = title => {
    const disabledLinksInKYCMode = [
      'History',
      'Referrals',
      'Admin',
      'Levels',
      'Settings',
      'Support',
      'Tiers',
      'Trade',
      'Markets',
    ];

    return disabledLinksInKYCMode.includes(title) && !isUserActivityStatusActive;
  };

  return (
    <>
      {options.map(
        (item, index) =>
          (item.isMobileOnly ? isMobile === true : true) && (
            <NavDropdown.Item className={style['nav-item']} key={index + item.title}>
              {item.hasTopSeparator && <hr className={style['nav-item__divider']} />}
              {checkIfLinkDisabled(item.title) ? (
                <button className={clsx(className, item.className)} data-disabled={true}>
                  <span>
                    {item.icon}
                    {item.title}
                  </span>
                </button>
              ) : (
                <Link href={item.href}>
                  <a
                    onClick={event => {
                      item.onClick?.(event);
                      onLinkClick?.();
                    }}
                    className={clsx(className, item.className, { [style['is-active']]: router.pathname === item.href })}
                  >
                    <span className={style['nav-item__content']}>
                      {item.icon}
                      <span className={style['nav-item__content-title']}>{item.title}</span>
                    </span>
                    {item.label && <span className={style['nav-item__label']}>{item.label}</span>}
                    {item.hasArrow && <RightArrowIcon />}
                  </a>
                </Link>
              )}

              {item.hasBottomSeparator && <hr className={style['nav-item__divider']} />}
            </NavDropdown.Item>
          )
      )}
    </>
  );
};

export default NavbarDropdownItems;
