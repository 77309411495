import { NavDropdown } from 'react-bootstrap';
import { LinkDropDownItem } from '@ping/components/Header/LinkDropDownItem';
import { LinkProps } from 'next/link';
import { ReactElement } from 'react';

import style from './style.module.scss';

type TLinkDropDownItem = {
  href: LinkProps['href'];
  title: string;
  subtitle: string;
  icon: ReactElement;
};

interface IProps {
  title: string;
  disabled: boolean;
  link: TLinkDropDownItem[];
}

export const LinkWithDropdown = ({ title, disabled, link }: IProps) => {
  return (
    <NavDropdown
      data-testid='trade-dropdown'
      id={style['trade-dropdown']}
      title={title}
      className={style['header__link']}
      disabled={disabled}
    >
      {link?.map(item => {
        return (
          <NavDropdown.Item className={style['trade__dropdown-item']} key={item.title}>
            <LinkDropDownItem title={item.title} subtitle={item.subtitle} icon={item.icon} href={item.href} />
          </NavDropdown.Item>
        );
      })}
      {!link || (link.length === 0 && null)}
    </NavDropdown>
  );
};
