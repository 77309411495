import { modalKeys } from '@ping/constants/modal-keys.constant';
import { useRemoteModal } from '@ping/hooks';
import { nop, Store } from '@ping/utils';

import type { IModalKey } from '@ping/constants/modal-keys.constant';

type IModalRemoteState = ReturnType<typeof useRemoteModal>['state'];
type IModalStore = Record<IModalKey, IModalRemoteState>;

const FALLBACK_API: IModalRemoteState = {
  isOpen: false,
  setOpen: nop,
  open: nop,
  close: nop,
  toggle: nop,
} as const;

// --------------------------------------------------------------------------------
// MODAL STORE
// --------------------------------------------------------------------------------
export const useModalStore = new Store<IModalStore>({}).withDevTools().build();

// --------------------------------------------------------------------------------
// MODAL STORE HELPERS
// --------------------------------------------------------------------------------
const getModalRemoteStateWithFallback = (key: IModalKey) => (state: IModalStore) => {
  return state?.[key] ?? FALLBACK_API;
};

// --------------------------------------------------------------------------------
// MODAL STORE SELECTORS
// --------------------------------------------------------------------------------
export const importantNoticeModalRemoteStateSelector = getModalRemoteStateWithFallback(modalKeys.IMPORTANT_NOTICE);
export const receiveUserDataModalRemoteStateSelector = getModalRemoteStateWithFallback(modalKeys.RECEIVED_USER_DATA);
export const entityTypeDeclarationModalRemoteStateSelector = getModalRemoteStateWithFallback(
  modalKeys.ENTITY_TYPE_DECLARATION
);
export const uploadDocumentsModalRemoteStateSelector = getModalRemoteStateWithFallback(modalKeys.UPLOAD_DOCUMENTS);
export const provideRequestedDocumentsRemoteStateSelector = getModalRemoteStateWithFallback(
  modalKeys.PROVIDE_REQUESTED_DOCUMENTS
);
export const pendingApprovalModalRemoteStateSelector = getModalRemoteStateWithFallback(modalKeys.PENDING_APPROVAL);
export const pendingForReviewModalRemoteStateSelector = getModalRemoteStateWithFallback(modalKeys.PENDING_FOR_REVIEW);
export const withdrawalTierUpgradeModalRemoteStateSelector = getModalRemoteStateWithFallback(
  modalKeys.WITHDRAWAL_TIER_UPGRADE
);
export const formBModalRemoteStateSelector = getModalRemoteStateWithFallback(modalKeys.FORM_B);
export const upgradeTier3WarningModalRemoteStateSelector = getModalRemoteStateWithFallback(
  modalKeys.UPGRADE_TIER_3_WARNING
);
export const complianceApproveModalRemoteStateSelector = getModalRemoteStateWithFallback(modalKeys.COMPLIANCE_APPROVE);
export const complianceRejectModalRemoteStateSelector = getModalRemoteStateWithFallback(modalKeys.COMPLIANCE_REJECT);
export const complianceDowngradeUserTierModalRemoteStateSelector = getModalRemoteStateWithFallback(
  modalKeys.COMPLIANCE_DOWNGRADE_USER_TIER
);
export const claimRewardsModalRemoteStateSelector = getModalRemoteStateWithFallback(modalKeys.CLAIM_REWARDS);
export const transferOnProcessingModalRemoteStateSelector = getModalRemoteStateWithFallback(
  modalKeys.TRANSFER_ON_PROCESSING
);
export const warningMarketModalRemoteStateSelector = getModalRemoteStateWithFallback(modalKeys.MARKET_ORDER_WARNING);
export const marketPairsModalRemoteStateSelector = getModalRemoteStateWithFallback(modalKeys.PAIR_SELECTION);
export const userDetailsModalRemoteStateSelector = getModalRemoteStateWithFallback(modalKeys.USER_DETAILS);
export const fiatPaymentDetailsModalRemoteStateSelector = getModalRemoteStateWithFallback(
  modalKeys.FIAT_PAYMENT_DETAILS
);
export const apiKeysModalRemoteStateSelector = getModalRemoteStateWithFallback(modalKeys.API_KEYS);
export const apiKeysDetailsModalRemoteStateSelector = getModalRemoteStateWithFallback(modalKeys.API_KEYS_DETAILS);
// --------------------------------------------------------------------------------
// MODAL STORE API
// --------------------------------------------------------------------------------
export const modalStore = {
  register: (key: IModalKey, state: IModalRemoteState) => {
    useModalStore.setState($modals => ({ ...$modals, [key]: state }));
  },
  getRemoteState: (key: IModalKey) => {
    return getModalRemoteStateWithFallback(key)(useModalStore.getState());
  },
  isRegistered: (key: IModalKey) => {
    return Boolean(useModalStore.getState()?.[key]);
  },
};
