import { isBrowser } from '@ping/utils';
import { useRouter } from 'next/router';
import { ComponentProps, memo, useMemo } from 'react';
import { QueryParamProvider } from 'use-query-params';

type Props = Omit<
  ComponentProps<typeof QueryParamProvider>,
  'ReactRouterRoute' | 'reachHistory' | 'history' | 'location'
> & { shallow?: boolean };

const pathnameRegex = /[^?#]+/u;

function NextQueryParamProvider({ children, shallow = true, ...rest }: Props) {
  const router = useRouter();
  const match = router.asPath.match(pathnameRegex);
  const pathname = match ? match[0] : router.asPath;

  const location = useMemo(() => {
    if (isBrowser()) {
      return window.location as Location;
    } else {
      // On the server side we only need a subset of the available
      // properties of `Location`. The other ones are only necessary
      // for interactive features on the client.
      return { search: router.asPath.replace(pathnameRegex, '') } as Location;
    }
  }, [router.asPath, router.isReady]);

  const history = useMemo(() => {
    function createUpdater(routeFn: typeof router.push) {
      return function updater({ hash, search }: { hash?: string; search?: string }) {
        const query = search ? Object.fromEntries(new URLSearchParams(search).entries()) : undefined;

        routeFn({ pathname: router.pathname, query, hash }, undefined, { shallow, scroll: false });
      };
    }

    return {
      push: createUpdater(router.push),
      replace: createUpdater(router.replace),
      location,
    };
  }, [location, pathname, router, shallow]);

  return (
    <QueryParamProvider {...rest} history={history} location={location}>
      {children}
    </QueryParamProvider>
  );
}

export default memo(NextQueryParamProvider);
