import { Modal } from '@ping/uikit';
import { LinkDropDownItem } from '@ping/components/Header/LinkDropDownItem';
import RightArrowIcon from '@ping/assets/Icon/right-arrow.svg';
import { LinkProps } from 'next/link';
import { ReactElement } from 'react';
import type { IModalContext } from '@ping/uikit';

import style from './style.module.scss';

type TLinkDropDownItem = {
  href: LinkProps['href'];
  title: string;
  subtitle: string;
  icon: ReactElement;
};

interface IProps {
  title: string;
  link: TLinkDropDownItem[];
}

export const DropDownLinkMobileModalTrigger = ({ title, link }: IProps) => {
  const handleOnClose = (ctx: IModalContext) => {
    ctx.state.close();
  };

  return (
    <Modal.Root>
      <Modal.Context.Consumer>
        {ctx => (
          <>
            <Modal.Trigger className={style['trade-drop-down-modal-trigger']}>
              <span className={style['trade-drop-down-modal-trigger__text']}>{title}</span>
              <RightArrowIcon />
            </Modal.Trigger>

            <Modal.Portal className={style['trade-drop-down-modal']} isDismissable>
              <div className={style['trade-drop-down-modal__divider']}>
                <hr />
              </div>
              <Modal.Header className={style['trade-drop-down-modal__header']}>
                <span>{title}</span>
              </Modal.Header>
              <Modal.Content className={style['trade-drop-down-modal--content']}>
                {link?.map(item => (
                  <LinkDropDownItem
                    title={item.title}
                    subtitle={item.subtitle}
                    icon={item.icon}
                    href={item.href}
                    key={item.title}
                    onClick={() => handleOnClose(ctx)}
                  />
                ))}
              </Modal.Content>
            </Modal.Portal>
          </>
        )}
      </Modal.Context.Consumer>
    </Modal.Root>
  );
};
